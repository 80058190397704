import logoDark from "@/images/logo.png";
import logoLight from "@/images/logo-dark.png";
import sliderOne1 from "@/images/banner-1-1.jpg";
import sliderOne2 from "@/images/banner-1-2.jpg";
import sliderTwo1 from "@/images/banner-featured-1-1.png";
import video1 from "@/images/video-box-1-1.jpg";
import callToActionTwo1 from "@/images/free-sample-1-2.jpg";
import aboutThree1 from "@/images/service-bg-1-1.jpg";
import aboutTwo1 from "@/images/nosotros.jpg";
import featureCarousel1 from "@/images/we-belive-in-1-1.jpg";
import featureCarousel2 from "@/images/we-belive-in-1-2.jpg";
import team1 from "@/images/team-1-1.png";
import team2 from "@/images/team-1-2.png";
import team3 from "@/images/team-1-3.png";
import testimonials1 from "@/images/testi-1-1.jpg";
import testimonials2 from "@/images/testi-1-2.jpg";
import aboutOne1 from "@/images/mision.jpg";
import aboutOne2 from "@/images/vision.jpg";
import aboutOne3 from "@/images/valores.jpg";
import serviceDetails1 from "@/images/service-details-1-1.jpg";
import serviceDetails2 from "@/images/service-details-1-2.jpg";
import serviceDetails3 from "@/images/service-details-1-3.jpg";
import feature1 from "@/images/what-we-do-3-1.jpg";
import feature2 from "@/images/what-we-do-3-1-1.jpg";
import feature3 from "@/images/what-we-do-3-1-2.jpg";
import feature4 from "@/images/what-we-do-3-1-3.jpg";
import serviceImage1 from "@/images/service-1-1.jpg";
import serviceImage2 from "@/images/service-1-2.jpg";
import serviceImage3 from "@/images/service-1-3.jpg";
import serviceImage4 from "@/images/service-1-4.jpg";
import serviceImage5 from "@/images/service-1-5.jpg";
import serviceImage6 from "@/images/service-1-6.jpg";
import projectImage1 from "@/images/project-1-1.jpg";
import projectImage2 from "@/images/project-1-2.jpg";
import projectImage3 from "@/images/project-1-3.jpg";
import projectImage4 from "@/images/project-1-4.jpg";
import projectImage5 from "@/images/project-1-5.jpg";
import projectImage6 from "@/images/project-1-6.jpg";
import errorImage from "@/images/404-text.png";
import clientImage1 from "@/images/intel.png";
import clientImage2 from "@/images/amd.png";
import clientImage3 from "@/images/microsoft.png";
import clientImage4 from "@/images/apple.png";
import clientImage5 from "@/images/dell.png";
import clientImage6 from "@/images/canon.png";
import clientImage7 from "@/images/epson.png";
import clientImage8 from "@/images/ricoh.png";
import clientImage9 from "@/images/brother.png";
import clientImage10 from "@/images/hp.png";
import progressImage1 from "@/images/what-we-do-2-1.jpg";
import sidebarImage from "@/images/widget-1-1.png";
import blogImage1 from "@/images/blog-1-1.jpg";
import blogImage2 from "@/images/blog-1-2.jpg";
import blogImage3 from "@/images/blog-1-3.jpg";
import blogImage4 from "@/images/blog-1-4.jpg";
import blogImage5 from "@/images/blog-1-5.jpg";
import blogImage6 from "@/images/blog-1-6.jpg";
import blogDetailsImage1 from "@/images/blog-details-1-1.jpg";
import commentImage1 from "@/images/comment-1-1.png";
import commentImage2 from "@/images/comment-1-2.png";

export const Logo = {
  dark: logoDark,
  light: logoLight
};

export const NavLinksData = [
  {
    name: "Inicio",
    url: "/",
  },
  {
    name: "Nosotros",
    url: "/nosotros"
  },
  {
    name: "Servicios",
    url: "/servicios",
  },
  {
    name: "Contacto",
    url: "/contacto"
  }
];

export const SliderOneData = [
  {
    image: sliderOne1,
    title: "Especialistas en \n Equipo tecnológico",
    subText: "Trabajamos con las mejores marcas del mercado",
    button: {
      label: "Nuestros Servicios",
      url: "/servicios"
    }
  },
  {
    image: sliderOne2,
    title: "Soluciones en \n Impresión",
    subText: "Ofrecemos soluciones de impresión para tu negocio",
    button: {
      label: "Nuestros Servicios",
      url: "/servicios"
    }
  }
];

export const SliderTwoData = {
  image: sliderTwo1,
  posts: [
    {
      title: "Quality \n Printing \n Service",
      subTitle: "We believe in the power of great design",
      button: {
        label: "Our Pricing",
        url: "/pricing"
      }
    },
    {
      title: "Quality \n Printing \n Service",
      subTitle: "We believe in the power of great design",
      button: {
        label: "Our Pricing",
        url: "/pricing"
      }
    }
  ]
};


export const VideoData = {
  image: video1,
  ID: "mUhcFs6v-Xw",
  caption: "Printfinity Makes \n Every Card \n Unique"
};

export const CallToActionData = {
  sectionContent: {
    subText: "Business stationery printing",
    title: "Even More Good \n Stuff",
    textAlignment: "left"
  },
  lists: [
    "Professional designs with added fizz",
    "Create an army of business stationery",
    "Take your attention to detail up a level",
    "Totally safe for laser printers"
  ],
  button: {
    label: "Learn More",
    url: "/about"
  }
};



export const CallToActionTwoData = {
  sectionContent: {
    subText: "Soporte técnico",
    title: "Servicio de \n Soporte Técnico e \n Instalación",
    textAlignment: "left"
  },
  content:
    "Savvy ofrece un servicio de soporte técnico e instalación de equipos tecnológicos, con el fin de brindarle a nuestros clientes la mejor experiencia en el uso de sus equipos.",
  button: {
    label: "Solicitar",
    url: "/contacto"
  },
  image: {
    name: callToActionTwo1,
    caption: "Profesionales Capacitados a su Servicio"
  }
};

export const CallToActionThreeData = {
  title: "Try Our New \n  Premium Extra \n  Thick Cards.",
  subText: "Thickest business card",
  button: {
    label: "Get Sample",
    url: "/contact"
  }
};

export const AboutThreeData = {
  backgroundImage: aboutThree1,
  sectionContent: {
    subText: "Servicios",
    title: "Los servicios \n que ofrecemos",
    textAlignment: "left"
  },
  content:"Savvy amplifica el valor de su posición en la intersección de miles de marcas, fabricantes y socios al personalizar y entregar aplicaciones altamente específicas para verticales de la industria, clientes de empresa a empresa y necesidades comerciales. Desde soluciones de aprovisionamiento para integradores de sistemas que trabajan en el corazón de la red, hasta ofertas a través del ciclo de vida completo de los dispositivos móviles, desde PYMES hasta software e informática empresarial global, punto de venta a servicios en la nube, AV profesional a seguridad física; los clientes confían en Savvy en tener la experiencia y los recursos para ayudarlos a definir y alcanzar los límites de lo que es posible.",
    
  phone: "(662) 109-5611",
  posts: [
    {
      title: "Venta, reparación y mantenimiento de equipo de cómputo",
      icon: "printify-icon-computer-monitor-back-view",
      url: "/service-details"
    },
    {
      title: "Soluciones de impresión, venta y renta de equipos de impresión e instalación de consumibles",
      icon: "printify-icon-drawing-pens",
      url: "/service-details"
    },
    {
      title: "Cableado estructurado, seguridad perimetral, CCTV y control de acceso",
      icon: "printify-icon-crop-symbol",
      url: "/service-details"
    },
    {
      title: "Venta de servidores y almacenamiento NAS, instalacion, configuración y mantenimiento",
      icon: "printify-icon-cube-template",
      url: "/service-details"
    }
  ]
};


export const AboutTwoData = {
  sectionContent: {
    subText: "Nosotros",
    title: "Acerca de Savvy",
    textAlignment: "left"
  },
  content:
    "Savvy apoya a las empresas a desarrollarse plenamente en la rama de la tecnología, ayudándoles a maximizar el valor de la tecnología que fabrican, venden o utilizan. \n\n Con su infraestructura nacional y su enfoque en movilidad, el ciclo de vida de la tecnología, la cadena de suministro y las soluciones tecnológicas, Savvy permite a los socios comerciales operar de una forma eficiente y exitosa en los mercados a los que sirven. \n\n Creamos oportunidades de crecimiento y llegamos a empresas PYME que utilizan la tecnología para agregar escala, mejorar su servicio y productividad.",
  url: "/nosotros",
  image: aboutTwo1
};

export const FaqData = {
  sectionContent: {
    subText: "Question and answers",
    title: "Frequently \nAsked Questions",
    textAlignment: "left"
  },
  posts: [
    {
      title: "Want to make plan for retairment?",
      content:
        "Color Business Cards start at $19.99 for 50 cards. \n \n The final cost depends on which turnaround time and paperfinish options you've selected at checkout - you can get an accurate quote in seconds using our online cost calculator."
    },
    {
      title: "How I can get good plan for future for my children education?",
      content:
        "Color Business Cards start at $19.99 for 50 cards. \n \n The final cost depends on which turnaround time and paper/finish options you've selected at checkout - you can get an accurate quote in seconds using our online cost calculator."
    },
    {
      title: "I can get online appointmet with agent?",
      content:
        "Color Business Cards start at $19.99 for 50 cards. \n \n The final cost depends on which turnaround time and paper/finish options you've selected at checkout - you can get an accurate quote in seconds using our online cost calculator."
    },
    {
      title: "Need a good insurace for term plan?",
      content:
        "Color Business Cards start at $19.99 for 50 cards. \n \n The final cost depends on which turnaround time and paper/finish options you've selected at checkout - you can get an accurate quote in seconds using our online cost calculator."
    }
  ]
};


export const FeatureCarouselData = [
  {
    image: featureCarousel1,
    title: "We believe in the \n power of great \n design",
    text:
      "Design helps us stand out: from the clothes we wear, to the homes we live in, to the business cards we use. Design tells a story about \n us and what we stand for.",
    url: "/services"
  },
  {
    image: featureCarousel2,
    title: "We believe in the \n power of great \n design",
    text:
      "Design helps us stand out: from the clothes we wear, to the homes we live in, to the business cards we use. Design tells a story about \n us and what we stand for.",
    url: "/services"
  }
];



export const TeamOneData = {
  sectionContent: {
    title: "Meet the Team",
    subText: "The leadership",
    content:
      "There are many variations of passages of lorem Ipsum available, but \nthe majority have suffered alteration in some form."
  },
  posts: [
    {
      name: "Harry Woods",
      designation: "Chief marketing officer",
      image: team1,
      content:
        "Jessica leads our customer team and holds guardianship of our brand. \n \n An award-winning marketer, Jesscia launched our Boston office, taking a huge step forward in making PRINTIFY a global brand. She’s responsible for our brand strategy and making sure we stay close to our customers. Jessica’s two big passions are the ocean and music. She has a formidable record library – with her purple vinyl of Purple Rain the jewel in her collection."
    },
    {
      name: "Myrtie Lyons",
      designation: "Chief marketing officer",
      image: team2,
      content:
        "Jessica leads our customer team and holds guardianship of our brand. \n \n An award-winning marketer, Jesscia launched our Boston office, taking a huge step forward in making PRINTIFY a global brand. She’s responsible for our brand strategy and making sure we stay close to our customers. Jessica’s two big passions are the ocean and music. She has a formidable record library – with her purple vinyl of Purple Rain the jewel in her collection."
    },
    {
      name: "Lida Doyle",
      designation: "Chief marketing officer",
      image: team3,
      content:
        "Jessica leads our customer team and holds guardianship of our brand. \n \n An award-winning marketer, Jesscia launched our Boston office, taking a huge step forward in making PRINTIFY a global brand. She’s responsible for our brand strategy and making sure we stay close to our customers. Jessica’s two big passions are the ocean and music. She has a formidable record library – with her purple vinyl of Purple Rain the jewel in her collection."
    }
  ]
};


export const TestimonialsOneData = [
  {
    image: testimonials1,
    content:
      "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch.",
    name: "Christine Eve"
  },
  {
    image: testimonials2,
    content:
      "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch.",
    name: "Mildred Rodriguez"
  }
];



export const AboutOneData = [
  {
    title: "Mision",
    text:
      "Ser un socio comercial indispensble, el puente más valioso entre nuestros proveedores y clientes. \n\nContribuir moderadamente al crecimiento y a la rentabilidad de nuestros clientes, tanto proveedores como revendedores, de manera que sea difícil de replicar o sustituir.",
    image: aboutOne1,
    url: "/services"
  },
  {
    title: "Vision",
    text:
      "Savvy será conocido como una de las mejores formas de obtener tecnología en México.",
    image: aboutOne2,
    url: "/contact"
  },
  {
    title: "Valores",
    text:
      "Nos comprometemos con estos valores para guiar nuestras decisiones y nuestro comportamiento: \n\nColaboración \nHonestidad \nConstancia \nRespeto \nIntegridad \nInnovación",
    image: aboutOne3,
    url: "/pricing"
  }
];



export const ServiceDetailsData = [
  {
    image: serviceDetails1,
    title: "Surprisingly Affordable",
    lists: [
      "16–17 pt paper thickness",
      "A premium paper for less",
      "Also in Business Cards and Letterhead"
    ]
  },
  {
    image: serviceDetails2,
    title: "Available in Matte or Gloss",
    lists: [
      "Matte is shine free, so no glare",
      "Gloss makes colors really “pop”",
      "Both come at no extra cost"
    ]
  },
  {
    image: serviceDetails3,
    title: "Versatile Finish Options",
    lists: [
      "Coat both sides for extra protection",
      "Leave one side uncoated to write on",
      'Or get that "traditional" Postcard feel'
    ]
  }
];

export const ServiceTabOneData = [
  {
    title: "Mantenimiento \n de Equipo de \n Cómputo",
    icon: "printify-icon-computer-monitor-back-view",
    content: {
      title: "Mantenimiento \n de Equipo de \n Cómputo",
      subText: "Servicios",
      content:
        "Mantenimiento preventivo y correctivo de equipo de cómputo, venta de equipo de cómputo, instalación de software, y atención a fallas de equipo de cómputo."
    }
  },
  {
    title: "Servicios de \n Impresión \n y Consumibles",
    icon: "printify-icon-drawing-pens",
    content: {
      title: "Servicios de \n Impresión \n y Consumibles",
      subText: "Servicios",
      content:
        "Venta de equipo de impresión, instalación de consumibles, venta de consumibles, y servicio de impresión."
    }
  },
  {
    title: "Servidores, \n Almacenamiento y \n Mantenimiento",
    icon: "printify-icon-cube-template",
    content: {
      title: "Servidores, \n Almacenamiento y \n Mantenimiento",
      subText: "Servicios",
      content:
        "Venta de servidores, instalación de servidores, configuración de servidores, mantenimiento de servidores, instalación y configuración de almacenamiento NAS"
    }
  },
  {
    title: "Redes, \n CCTV y Controles de \n Acceso",
    icon: "printify-icon-crop-symbol",
    content: {
      title: "Redes, \n CCTV y Controles de \n Acceso",
      subText: "Servicios",
      content:
        "Cableado estructurado, instalación de cámaras de seguridad, instalación de controles de acceso, instalación de redes, configuración de redes, y alarmas de intrusión."
    }
  },
  {
    title: "Consolas de \n Videojuegos y PC's \n Gamer",
    icon: "printify-icon-hanging-billboard",
    content: {
      title: "Consolas de \n Videojuegos y PC's \n Gamer",
      subText: "Servicios",
      content:
        "Venta de consolas de videojuegos, venta de PC's Gamer, instalación de PC's Gamer, mantenimiento de PC's Gamer, y venta de accesorios."
    }
  }
];

export const FeatureTwoData = {
  sectionContent: {
    title: "Las mejores marcas en equipo de impresión",
    subText: " Next printing level ",
    content:
      "Take your business cards, Flyers and Stickers to the next level with exclusive and innovative features."
  },
  posts: [
    {
      name: "Business \n Cards",
      url: "/service-details",
      icon: "printify-icon-white-sheets"
    },
    {
      name: "Flyer \n Printing",
      url: "/service-details",
      icon: "printify-icon-brochure-folded"
    },
    {
      name: "Sticker \n Printing",
      url: "/service-details",
      icon: "printify-icon-circular-sticker"
    }
  ]
};



export const FeatureOneData = {
  sectionContent: {
    title: "What We Do",
    subText: " Our features",
    content:
      "There are many variations of passages of lorem Ipsum available, but \n the majority have suffered alteration in some form."
  },
  posts: [
    {
      image: feature1,
      title: "We Make Every Card Unique",
      content:
        "Print a different image on the back of every single card at no upcharge. Watch the video for inspiration on how to get creative with Printfinity.",
      url: "/about"
    },
    {
      image: feature2,
      title: "Free Full-Color Printing",
      content:
        "Print full color on both sides of your business cards – it’s always included in the price.",
      url: "/about"
    },
    {
      image: feature3,
      title: "Rounded Corners for All",
      content:
        "Print full color on both sides of your business cards – it’s always included in the price.",
      url: "/about"
    },
    {
      image: feature4,
      title: "Satisfaction Guaranteed",
      content:
        "Print full color on both sides of your business cards – it’s always included in the price.",
      url: "/about"
    }
  ]
};



export const ServiceOneData = [
  {
    image: serviceImage1,
    title: "Business Cards",
    count: 50,
    price: 19.99,
    text:
      "Cut through the networking clutter and say hello with Printify Business Cards.",
    url: "/service-details"
  },
  {
    image: serviceImage2,
    title: "Postcards",
    count: 50,
    price: 19.99,
    text:
      "Cut through the networking clutter and say hello with Printify Business Cards.",
    url: "/service-details"
  },
  {
    image: serviceImage3,
    title: "Stickers and Labels",
    count: 50,
    price: 19.99,
    text:
      "Cut through the networking clutter and say hello with Printify Business Cards.",
    url: "/service-details"
  },
  {
    image: serviceImage4,
    title: "Flyers",
    count: 50,
    price: 19.99,
    text:
      "Cut through the networking clutter and say hello with Printify Business Cards.",
    url: "/service-details"
  },
  {
    image: serviceImage5,
    title: "Envelopes",
    count: 50,
    price: 19.99,
    text:
      "Cut through the networking clutter and say hello with Printify Business Cards.",
    url: "/service-details"
  },
  {
    image: serviceImage6,
    title: "Greeting Cards",
    count: 50,
    price: 19.99,
    text:
      "Cut through the networking clutter and say hello with Printify Business Cards.",
    url: "/service-details"
  }
];


export const ProjectOneData = [
  {
    image: projectImage1,
    category: "printing",
    title: "Thick Paper Book",
    url: "/project-details"
  },
  {
    image: projectImage2,
    category: "printing",
    title: "Ninety Nine You",
    url: "/project-details"
  },
  {
    image: projectImage3,
    category: "printing",
    title: "Colorful Photo Print",
    url: "/project-details"
  },
  {
    image: projectImage4,
    category: "printing",
    title: "Square Paper Book",
    url: "/project-details"
  },
  {
    image: projectImage5,
    category: "printing",
    title: "Book Copy",
    url: "/project-details"
  },
  {
    image: projectImage6,
    category: "printing",
    title: "C Creative Mess",
    url: "/project-details"
  }
];


export const errorData = {
  title: "Oops! This page is not available",
  text: "Please go back to home and try to find out once again.",
  image: errorImage
};


export const ProgressData = {
  sectionImage: progressImage1,
  sectionContent: {
    title: "You’ll get a \n perfect fit for \n your business.",
    subText: "What we do",
    content:
      "Handing out a business card is often the first impression people take of your business, so you need to get it right. Using your own photography or artwork to create custom business cards can help, and PRINTIFY makes the process easy with our simple online tools and templates."
  },
  posts: [
    {
      title: "Flyer \n Printing",
      price: "8.99",
      percentCount: "90"
    },
    {
      title: "Sticker \n Printing",
      price: "9.99",
      percentCount: "70"
    }
  ]
};

export const PricingData = {
  sectionContent: {
    title: "Plans & Pricing",
    subText: "Choose best plan",
    content:
      "There are many variations of passages of lorem Ipsum available, but \n the majority have suffered alteration in some form."
  },
  posts: [
    {
      name: "Standard plan",
      price: "25.00",
      icon: "printify-icon-continuous-feed-paper",
      url: "/contact",
      lists: [
        {
          name: "Rounded & Colored"
        },
        {
          name: "Preimum Paper"
        },
        {
          name: "Extra Thick"
        }
      ]
    },
    {
      name: "Premium plan",
      price: "35.00",
      icon: "printify-icon-brochure-folded",
      url: "/contact",
      lists: [
        {
          name: "Rounded & Colored"
        },
        {
          name: "Preimum Paper"
        },
        {
          name: "Extra Thick"
        }
      ]
    },
    {
      name: "Ultimate plan",
      price: "45.00",
      icon: "printify-icon-circular-sticker",
      url: "/contact",
      lists: [
        {
          name: "Rounded & Colored"
        },
        {
          name: "Preimum Paper"
        },
        {
          name: "Extra Thick"
        }
      ]
    }
  ]
};


export const ClientCarouselData = [
  {
    image: clientImage1
  },
  {
    image: clientImage2
  },
  {
    image: clientImage3
  },
  {
    image: clientImage4
  },
  {
    image: clientImage5
  }
];

export const ClientCarouselData2 = [
  {
    image: clientImage6
  },
  {
    image: clientImage7
  },
  {
    image: clientImage8
  },
  {
    image: clientImage9
  },
  {
    image: clientImage10
  }
];


export const sidebarTextWidgetData = {
  text:
    "Lorem Ipsum is simply dummy text of the rinting and typesetting industry has been the industry.",
  title: "Image with text",
  image: sidebarImage
};


export const BlogPostsData = [
  {
    title: "What final touches can i add",
    text:
      "We offer Letterpress, Spot Gloss, Raised Spot Gloss or Gold Foil. There are many people variation of passages of lorem Ipsum available in the majority alteration in some.",
    image: blogImage1,
    author: "admin",
    date: "20 March, 2018",
    commentCount: "3 Comments",
    url: "/blog-details"
  },
  {
    title: "How to brand a tattoo studio",
    text:
      "We offer Letterpress, Spot Gloss, Raised Spot Gloss or Gold Foil. There are many people variation of passages of lorem Ipsum available in the majority alteration in some.",
    image: blogImage2,
    author: "admin",
    date: "20 March, 2018",
    commentCount: "3 Comments",
    url: "/blog-details"
  },
  {
    title: "What file types do you accept",
    text:
      "We offer Letterpress, Spot Gloss, Raised Spot Gloss or Gold Foil. There are many people variation of passages of lorem Ipsum available in the majority alteration in some.",
    image: blogImage3,
    author: "admin",
    date: "20 March, 2018",
    commentCount: "3 Comments",
    url: "/blog-details"
  },
  {
    title: "Do you offer design services",
    text:
      "We offer Letterpress, Spot Gloss, Raised Spot Gloss or Gold Foil. There are many people variation of passages of lorem Ipsum available in the majority alteration in some.",
    image: blogImage4,
    author: "admin",
    date: "20 March, 2018",
    commentCount: "3 Comments",
    url: "/blog-details"
  },
  {
    title: "Bleed, trim and safe area",
    text:
      "We offer Letterpress, Spot Gloss, Raised Spot Gloss or Gold Foil. There are many people variation of passages of lorem Ipsum available in the majority alteration in some.",
    image: blogImage5,
    author: "admin",
    date: "20 March, 2018",
    commentCount: "3 Comments",
    url: "/blog-details"
  },
  {
    title: "Can I use my own logo",
    text:
      "We offer Letterpress, Spot Gloss, Raised Spot Gloss or Gold Foil. There are many people variation of passages of lorem Ipsum available in the majority alteration in some.",
    image: blogImage6,
    author: "admin",
    date: "20 March, 2018",
    commentCount: "3 Comments",
    url: "/blog-details"
  }
];


export const BlogDetailsData = {
  title: "What final touches can i add",
  text:
    "We offer Letterpress, Spot Gloss, Raised Spot Gloss or Gold Foil. There are many people variation of passages of lorem Ipsum available in the majority alteration in some.",
  image: blogDetailsImage1,
  author: "admin",
  date: "20 March, 2018",
  commentCount: "3 Comments",
  url: "/blog-details"
};


export const BlogCommentData = {
  sectionContent: {
    title: "2 Comments",
    subText: "Read comments"
  },
  posts: [
    {
      image: commentImage1,
      title: "Jayme Secord",
      date: "20 Mar, 2018",
      time: "4:00 pm",
      content:
        "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer nullam condimentum purus. In non ex at ligula fringilla lobortis et mauris auctor aliquet."
    },
    {
      image: commentImage2,
      title: "Lottie Golda",
      date: "20 Mar, 2018",
      time: "4:00 pm",
      content:
        "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer nullam condimentum purus. In non ex at ligula fringilla lobortis et mauris auctor aliquet."
    }
  ]
};

export const CommentFromData = {
  sectionContent: {
    title: "Write comment",
    subText: "Leave Comment"
  }
};

export const ContactFromData = {
  sectionContent: {
    title: "Envianos un mensaje",
    subText: "Contactanos"
  }
};

export const ContactInfosData = {
  sectionContent: {
    title: "Detalles",
    subText: "Información de contacto",
    textAlignment: "center"
  },
  posts: [
    {
      title: "Domicilio",
      text: "Boulevard Antonio Quiroga Sur #150B \n Col. Corceles Hermosillo, Sonora, México \nCP. 83243"
    },
    {
      title: "Teléfono",
      text: "01 (662) 109 56 11"
    },
    {
      title: "Correo electrónico",
      text: "contacto@savvy.com"
    },
  ]
};

export const FooterAboutData = {
  text:
    "Representamos a más de 1.700 proveedores, incluidos AMD, Intel, Apple, Microsoft, Dell, Asus, Kingston, Corsair, EVGA, ADATA, PNG, Gigabyte, Honeywell, HP, Lenovo, Samsung, Cisco, VMware, Zebra y otros."
};

export const FooterLinksData = {
  title: "Enlaces",
  links: [
    {
      label: "Nosotros",
      url: "/nosotros"
    },
    {
      label: "Contacto",
      url: "/contacto"
    },
    {
      label: "Nuestros Servicios",
      url: "/servicios"
    },
  ]
};

export const FooterContactData = {
  title: "Contacto",
  infos: [
    {
      text: "01 (662) 109 56 11",
      url: "tel:662-100-00-00"
    },
    {
      text: "contacto@savvy.com",
      url: "mailto:contacto@savvy.com"
    },
    {
      text: "Boulevard Antonio Quiroga Sur #150B \nCol. Corceles Hermosillo, Sonora, México \nCP. 83243",
      url: "https://goo.gl/maps/G4fdV35VC5BpVT4W8"
    }
  ]
};
